@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f7f9fc;
  font-family: "Poppins", sans-serif;
}

.radio-custom:checked {
  background-color: #00ad51;
  border-color: #00ad51;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" style="color: gray" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.66669 0.833344C7.12692 0.833344 7.50002 1.20644 7.50002 1.66668V2.50001H12.5V1.66668C12.5 1.20644 12.8731 0.833344 13.3334 0.833344C13.7936 0.833344 14.1667 1.20644 14.1667 1.66668V2.50001H15C16.841 2.50001 18.3334 3.99239 18.3334 5.83334V15C18.3334 16.841 16.841 18.3333 15 18.3333H5.00002C3.15907 18.3333 1.66669 16.841 1.66669 15V5.83334C1.66669 3.99239 3.15907 2.50001 5.00002 2.50001H5.83335V1.66668C5.83335 1.20644 6.20645 0.833344 6.66669 0.833344ZM12.5 4.16668C12.5 4.62691 12.8731 5.00001 13.3334 5.00001C13.7936 5.00001 14.1667 4.62691 14.1667 4.16668H15C15.9205 4.16668 16.6667 4.91287 16.6667 5.83334V6.25001H3.33335V5.83334C3.33335 4.91287 4.07955 4.16668 5.00002 4.16668H5.83335C5.83335 4.62691 6.20645 5.00001 6.66669 5.00001C7.12692 5.00001 7.50002 4.62691 7.50002 4.16668H12.5ZM16.6667 7.91668H3.33335V15C3.33335 15.9205 4.07955 16.6667 5.00002 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V7.91668Z" fill="currentColor"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 12px;
}
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}